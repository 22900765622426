#ruvi-start {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: white;
    overflow-y: scroll !important;
}

.row {
    text-align: left;
    margin-bottom: 10px;
}

/* .table tr th {
    white-space: nowrap;
} */