#ruvi-obsrequestsanswered {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: white;
    overflow-y: scroll !important;
}

td:hover {
    background-color:#F4F3F2;
}

td:active {
    background-color:#EEEEEE;
}

#obs-list-ans {
    margin-bottom: 100px;
    margin-top: 125px;
}

@media screen and (min-width: 416px) {
    #obs-list-ans {
        margin-top: 90px;
    }
}

.navbar {
    justify-content: center;
    padding-top: 15px;
    margin-left: 15px;
    margin-right: 15px;
}
