.camera {
    display: block;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    overflow: hidden;
}

.btn-take-photo {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}

.camera video#device_stream_display {
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.camera video#device_photo_memory {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -2;
}

.message {
  z-index: 1;
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.message span {
  width: 50%;
}

#cancel {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.shooter {
  position: absolute;
  z-index: 2;
}