#ruvi-obsrequestinfo {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: white;
    overflow-y: scroll !important;
}

#ruvi-picture {
    max-width: 50%;
    margin-left: 25%;
}