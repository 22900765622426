#ruvi-onmap {
    height: 100%;
    width: 100%;
    z-index: 2;
}

#map-back {
    position: fixed;
    bottom: 20px;
    left: 5%;
    width: 90%;
}

#help {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 45%;
    padding: 5px;
    background-color: white;
}

#camera {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: white;
}

#save-photo {
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: white;
}

#take-again {
    position: absolute;
    left: 10px;
    bottom: 10px;
}

#save-button {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

#distance {
    padding: 5px;
    margin-bottom: 10px;
}